import React from "react";

import { Grid4 } from "./css";

let portfolio = require("../properties.json");

export default function Portfolio() {


  return (
    <Grid4>

      {
        portfolio.map((port, i) => {
          return (

            <div style={{ background: "white" }}>
              <h4 style={{ background: "white", color: "#57a2b9", margin: 0, padding: 5 }}>{port.property_name}</h4>
              <p style={{ margin: 0, padding: 5, background: "#57a2b9", color: "white" }}>{port.street_address}</p>
              <img style={{ width: "100%" }} src={port.best_image} />
            </div>

          )
        })
      }

    </Grid4>


  )

}