import React from "react";

import { Container, SEOPage } from "../components";

import { StyledPage } from "../components/css";

import Portfolio from "../components/portfolio";

export default function PortfolioPage() {

  //On page SEO
  const SEO = {
    entity: "page",
    title: "Contact us",
    keywords: `investments, tenant representation, sales, leasing`,
    description: `No service is out of our skill set.`,
    url: `/services`,
  }

  return (
    <Container page="content" breadcrumbs={true} breadData={{ type: "content", title: SEO.title }}>

      {/* Page meta data (title, description, etc.)*/}
      <SEOPage
        entity={SEO.entity}
        title={SEO.title}
        keywords={SEO.keywords}
        description={SEO.description}
        url={SEO.url}
      />

      <div style={{ padding: "0.5rem 1rem 0 1rem", maxWidth: 1200, margin: "0 auto 2rem auto" }}>
        <h1 style={{ marginBottom: 0 }}>Portfolio</h1>
      </div>

      <StyledPage>

        <Portfolio />

      </StyledPage>

    </Container>
  )
}